/**
 * NOTA: essa classe serve para simplificar o acesso
 * aos elementos do AppBar.
 * Deveria ser .app-bar, mas para evitar conflito
 * com o material-ui, esse nome será usado.
*/

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Roboto:wght@300;400;500;700;900&display=swap");

body {
	font-family: "Roboto", sans-serif !important;
}

.ui.header {
	border: none;
	margin: calc(2rem - 0.14285714em) 0 1rem;
	padding: 0 0;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	line-height: 1.28571429em;
	text-transform: none;
	color: rgba(0, 0, 0, 0.87);
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
	font-family: "Roboto", sans-serif;
	margin: 0;
	outline: 0;
	-webkit-appearance: none;
	tap-highlight-color: rgba(255, 255, 255, 0);
	line-height: 1.21428571em;
	padding: 0.67857143em 1em;
	font-size: 1em;
	background: #fff;
	border: 1px solid rgba(34, 36, 38, 0.15);
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	box-shadow: 0 0 0 0 transparent inset;
	-webkit-transition:
		color 0.1s ease,
		border-color 0.1s ease;
	transition:
		color 0.1s ease,
		border-color 0.1s ease;
}
.ui.action.input:not([class*="left action"]) > input {
	border-radius: 5x;
}
.ui.action.input > .button {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}
.supbar {
	background: #ffffff;
	color: #333;
	float: left;
	padding: 13px;
	padding-left: 20px;
	display: flex;
	position: fixed;
	width: calc(100% - 85px);
	z-index: 998;
}

.footer {
	/* background-image: linear-gradient(-45deg, #f8f8f8 0%, #eee 100%); */
	width: calc(100% - 10px);
	height: 80px;
	padding: 30px;
	line-height: 100px;
	position: relative;
	display: table;
	padding-bottom: 0px;
	/* margin-bottom: 0px; */
	text-align: center;
	border-radius: 20px;
	/* margin-bottom: 5px; */
}

.relative-margin-left {
	padding-left: 85px;
}
.relative-margin-top {
	padding-top: 75px;
}

.ui.purple.label {
	background: #662780 !important;
	border-color: #662780 !important;
}

/* Alguma API adicina essa classe de maneira
   intrusiva. Desabilitando. */

.MuiListSubheader-sticky-94 {
	position: initial !important;
}

@media (min-width: 600px) {
	.relative-margin {
		padding-top: 0px;
	}
}

.text-white {
	color: #fff;
}

.ui.segment.purple-gradient-87 {
	background: #7501c0 !important;
}

.ui.button {
	color: #333;
}

.cor-padrao .ui.green.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #35be35 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}

.ui.input {
	border-radius: 5px;
}
.ui.fluid.input > input:focus {
	border: 1px solid #662780;
}
.ui.selection.dropdown,
.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.fluid.input > input {
	border-radius: 5px !important;
}

.form-oportunidades input,
.form-oportunidades .ui.selection.dropdown,
.form-oportunidades .ui.fluid.input input[type="time"],
.form-oportunidades .ui.fluid.input input[type="date"] {
	padding: 15px !important;
	border-radius: 5px !important;
	background: #fafafa;
}

.ui.input.labeled > .ui.label {
	border-radius: 5px 0px 0px 5px !important;
}

.ui.input.labeled > input {
	border-radius: 0px 5px 5px 0px !important;
}

.ui.segment {
	border-radius: 0px 5px 5px 0px !important;
}

.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

.ui.attached:not(.message) + .ui.attached.segment:not(.bottom) {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.ui.top.attached.header {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
}

/* PERSONALIZAÇÃO DE CORES DO SEMANTIC */
.ui.button {
	transition: ease-in-out all 0.2s !important;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
}
.ui.button.basic.inverted:hover {
	color: #662780 !important;
}

.ui.button.purple,
.ui.purple.buttons .button {
	background: #662780 !important;
}
.ui.button.purple:hover,
.ui.purple.buttons .button:hover {
	background: #4f1e63 !important;
}
button.ui.basic.purple.buttons.button,
button.ui.basic.purple.button {
	color: #662780 !important;
	box-shadow: 0 0 0 1px #662780 inset !important;
}
button.ui.basic.purple.buttons.button:hover,
button.ui.basic.purple.button:hover {
	color: #4f1e63 !important;
	box-shadow: 0 0 0 1px #4f1e63 inset !important;
}
div.ui.purple.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #662780 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}
div i.purple.icon {
	color: #662780 !important;
}

.ui.button.red {
	background: #f44336 !important;
}
.ui.button.red:hover {
	background: rgb(214, 58, 47) !important;
}
button.ui.basic.red.buttons.button,
button.ui.basic.red.button {
	color: #f44336 !important;
	box-shadow: 0 0 0 1px #f44336 inset !important;
}
button.ui.basic.red.buttons.button:hover,
button.ui.basic.red.button:hover {
	color: rgb(214, 58, 47) !important;
	box-shadow: 0 0 0 1px rgb(214, 58, 47) inset !important;
}
div.ui.red.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #f44336 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}
div i.red.icon {
	color: #f44336 !important;
}

.ui.button.green {
	background: #35be35 !important;
}
.ui.button.green:hover {
	background: rgb(47, 170, 47) !important;
}
button.ui.basic.green.buttons.button,
button.ui.basic.green.button {
	color: #35be35 !important;
	box-shadow: 0 0 0 1px #35be35 inset !important;
}
button.ui.basic.green.buttons.button:hover,
button.ui.basic.green.button:hover {
	color: rgb(47, 170, 47) !important;
	box-shadow: 0 0 0 1px rgb(47, 170, 47) inset !important;
}
div.ui.green.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #35be35 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}
div i.green.icon {
	color: #35be35 !important;
}

.ui.button.blue {
	background: #1e88e5 !important;
}
.ui.button.blue:hover {
	background: rgb(27, 121, 204) !important;
}
button.ui.basic.blue.buttons.button,
button.ui.basic.blue.button {
	color: #1e88e5 !important;
	box-shadow: 0 0 0 1px #1e88e5 inset !important;
}
button.ui.basic.blue.buttons.button:hover,
button.ui.basic.blue.button:hover {
	color: rgb(27, 121, 204) !important;
	box-shadow: 0 0 0 1px rgb(27, 121, 204) inset !important;
}
div.ui.blue.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #1e88e5 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}
div i.blue.icon {
	color: #1e88e5 !important;
}

.ui.button.orange {
	background: #ff6f00 !important;
}
.ui.button.orange:hover {
	background: rgb(236, 103, 0) !important;
}
button.ui.basic.orange.buttons.button,
button.ui.basic.orange.button {
	color: #ff6f00 !important;
	box-shadow: 0 0 0 1px #ff6f00 inset !important;
}
button.ui.basic.orange.buttons.button:hover,
button.ui.basic.orange.button:hover {
	color: rgb(236, 103, 0) !important;
	box-shadow: 0 0 0 1px rgb(236, 103, 0) inset !important;
}
div.ui.orange.segment:not(.inverted) {
	border: 0px !important;
	border-left: 3px solid #ff6f00 !important;
	box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}
div i.orange.icon {
	color: #ff6f00 !important;
}

button.ui.basic.button,
button.ui.basic.buttons,
a.ui.basic.button,
a.ui.basic.buttons {
	background: transparent none !important;
}
button.ui.basic.button.red:hover,
a.ui.basic.button.red:hover,
button.ui.basic.button.blue:hover,
a.ui.basic.button.blue:hover,
button.ui.basic.button.green:hover,
a.ui.basic.button.green:hover,
button.ui.basic.button.purple:hover,
a.ui.basic.button.purple:hover,
button.ui.basic.button.orange:hover,
a.ui.basic.button.orange:hover,
button.ui.basic.button:hover,
a.ui.basic.button:hover {
	background: #fafafa !important;
}
/* PERSONALIZAÇÃO DE CORES DO SEMANTIC */

.ui.attached.segment {
	border-radius: 0px !important;
}

.ui.inverted.attached.segment {
	border: none;
}

.ui.message.form-group {
	padding: 0.9em;
}

.header-transacoes input[type="date"] {
	margin-right: -40px !important;
}

.header-transacoes input[type="date"]::-webkit-clear-button,
.header-transacoes input[type="date"]::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
}

.ui.icon.left.input > input {
	border-radius: 5px !important;
}

.filtrar-roteiros > .equal.fields {
	margin: 0 !important;
}

@media only screen and (max-width: 767px) {
	.filtrar-roteiros .wide.field:not(:last-child) {
		margin: 0 0 10px !important;
	}
	.ui.app-search {
		width: 50px !important;
		min-width: 11em !important;
	}
	.PrimaryAppBar-menuButton-3 {
		margin-right: 0 !important;
	}
	.appbar .ui.logo {
		max-width: 110px !important;
	}
	.appbar .ui.search.dropdown {
		display: none;
	}
}

.loading-text {
	color: #7501c0;
}

.not-found {
	display: flex;
	flex-direction: column;
	height: 100vh;
	text-align: center;
	justify-content: center;
	justify-items: center;
}

.not-found .ui.header {
	background-color: #662780;
}

.not-found h1 {
	margin: 0;
	padding: 0;
	display: block;
	font-size: 192px;
	color: #fff;
}

.not-found p {
	margin: 0;
	padding: 0;
	font-size: 22px;
	color: #fff;
	padding-bottom: 23px;
}

.ui.input.menu-search {
	padding: 10px !important;
}

.ui.input.menu-search input,
.ui.dropdown.app-search {
	border-radius: 5px !important;
	background: rgba(34, 36, 38, 0.05) !important;
	color: #333 !important;
}

.ui.active.selection.dropdown.app-search {
	border-radius: 5px !important;
	border-color: #2e1a55 !important;
}

.app-search.active input {
	color: #333 !important;
	word-wrap: none;
	break-after: avoid;
	break-before: avoid;
}

.ui.input.menu-search .search.icon {
	margin-right: 14px;
	color: #fff;
}

.ui.input.menu-search > input:focus,
.ui.input.menu-search > input:active {
	background: #4f1e63 !important;
	color: #fff !important;
	border-color: #2e1a55 !important;
	-webkit-tap-highlight-color: #ccc !important;
}

.PrimaryAppBar-grow-120,
.PrimaryAppBar-grow-2 {
	text-align: right;
}

.app-search > .search.icon {
	top: 0.58571429em !important;
}

.appbar .ui.search.dropdown {
	min-width: 240px;
}

.header-positioning {
	height: 68px;
}

.header-label {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 30px;
}

.logo img {
	top: 50%;
	transform: translateY(-50%);
	display: block;
	position: relative;
}

.logo-loading-container {
	width: 96px;
	height: 96px;
	position: relative;
	margin: 0 auto;
}

.loading-container {
	border-radius: 50%;
	width: 96px;
	height: 96px;
}

.logo-loading {
	position: absolute;
	background-color: rgba(179, 61, 255, 0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	animation: logo-transition 2s infinite;
}

.logo-loading2 {
	position: absolute;
	background-color: rgba(179, 61, 255, 0.5);
	animation: flippe-logo-transition 2s infinite;
}

.logo-loading-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes logo-transition {
	0% {
		transform: scale(1.5, 1.5);
	}
	50% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(1.5, 1.5);
	}
}

@keyframes flippe-logo-transition {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.5, 1.5);
	}
	100% {
		transform: scale(1, 1);
	}
}

.page-loading {
	min-height: 60vh;
	display: flex;
	justify-content: center;
	flex-flow: column;
}

.bg-teste > span {
	display: block;
	line-height: initial;
}

.bg-teste > span > span {
	font-size: 14px;
	display: inherit;
}

.section-summary {
	padding: 10px 0;
}

.section-header {
	padding: 20px 0;
}

.section-list {
	padding: 0;
}

.section-list,
.section-list ul {
	list-style: none;
}

.section-list li {
	padding: 10px 0;
}

.reembolso {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.reembolso p {
	margin: 0 !important;
	padding: 0 !important;
}

.saldo-appbar:hover {
	color: #666;
}
.saldo-appbar {
	display: flex;
	flex-direction: column;
	position: relative;
	font-size: 18px;
	font-weight: bold;
	align-self: center;
	margin-left: 20px;
	color: #333;
	margin-right: 15px;
	text-decoration: none;
}
.saldo-appbar .saldo-faturamento {
	color: #666;
	font-weight: 300;
	font-size: 12px;
}
.snack div {
	color: #fafafa;
	font-size: 14px;
}
.snack.success {
	background: #35be35;
}
.snack.error {
	background: #f44336;
}
.snack.warning {
	background: #ff6f00;
}
.snack.info {
	background: #1e88e5;
}
.snack .message {
	display: "flex";
	align-self: "center";
	font-size: 14px;
}
.snack svg {
	margin-right: 10px;
}
.snack.margin {
	margin-top: 50px;
}
.lista-saques {
}
.lista-saques .pedido-saque {
	display: flex;
	flex-direction: row;
	background: #fafafa;
	margin: 10px 0px;
	padding: 10px;
	border-radius: 5px;
}
.lista-saques .pedido-saque div {
	flex: 1;
	align-self: center;
}
.lista-saques .pedido-saque .content {
	display: flex;
	flex-direction: column;
}
.dados-bancarios-saque {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	padding: 10px;
}
.dados-bancarios-saque div {
	flex: 1;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: bold;
}
.dados-bancarios-saque div span:first-child {
	text-transform: uppercase;
	font-weight: normal;
	font-size: 12px;
}

.container-oportunidade-expirada {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10%;
	padding-right: 10%;
	margin-top: 30px;
	background: #662780;
	color: #fff;
	border-radius: 10px;
}
.valor-carregar {
	background: #eee;
	padding: 20px;
	cursor: pointer;
	border-radius: 5px;
	display: block;
	position: relative;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	color: #333;
}
.valor-carregar.green {
	background-color: #35be35;
	color: #fafafa;
}
.valor-carregar > div {
	position: relative;
	margin: auto;
	display: block;
}
.taxa-cc {
	float: right;
	display: inline-block;
	color: #fafafa;
	background: #f44336;
	border-radius: 5px;
	font-size: 14px;
	font-weight: normal;
	padding-right: 10px;
	padding-left: 10px;
}
.header-confirma-aprovacao {
	margin: auto;
	position: relative;
	font-size: 18px;
	color: #666;
	background: #eee;
	display: table;
	padding: 5px 15px;
	border-radius: 5px;
	margin-bottom: 15px;
	font-weight: bold;
}
.sub-padrao {
	font-size: 16px;
	color: #999;
}
.sub-aviso {
	font-size: 16px;
	color: #f0630c;
}
.tit-padrao {
	text-transform: uppercase;
	font-weight: bold;
	color: #666;
	font-size: 18px;
}
.mensagem {
	position: fixed;
	bottom: 0px;
	z-index: 999;
	left: 10%;
}

.header-mensagem {
	width: 300px;
	background-image: linear-gradient(#7501c0, #b33dff);
	color: #fafafa;
	border-radius: 5px 5px 0px 0px;
	padding: 20px 15px;
	font-size: 16px;
	cursor: pointer;
}
.header-mensagem-promotor {
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	top: 50%;
	transform: translateY(50%);
}
.chat-mensagem {
	height: 40vh;
	width: 300px;
	transition: ease-in-out 0.5s;
	background: #fafafa;
}
.chat-list {
	height: calc(100% - 60px);
	overflow-y: scroll;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.send-message {
	padding: 0px 15px;
	display: block;
}
.send-message .input {
	top: 7px;
}
.chat-button {
	position: absolute;
	z-index: 9999;
	right: 10px;
}
.message-chat {
	max-width: 60%;
	border-radius: 10px;
	padding: 10px 15px;
	width: auto;
	display: inline-flex;
	margin: 2px;
}
.message-chat.receiver {
	background: #eee;
	align-self: flex-start;
}
.message-chat.sender {
	background-image: linear-gradient(#4f1e63, #662780);
	align-self: flex-end;
	color: #fafafa;
	word-break: break-all;
}
.group-message-time {
	color: #999;
	font-size: 12px;
}
.group-message-time.sender {
	align-self: flex-end;
}
.message-seen {
	align-self: flex-end;
	color: #999;
	font-size: 8px;
}
.message-day {
	font-size: 12px;
	color: #999;
	text-align: center;
}
.ui.red.label {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
}
.ui.floating.label {
	border-radius: 5px;
}
.chat-blocked {
	color: #666;
	text-align: center;
	padding: 10px;
	white-space: normal;
}
.selecao-automatica-info {
	padding: 20px;
	background: rgba(53, 190, 53, 0.25);
	border-radius: 10px;
}
.star-selecao-automatica {
	display: inline-block;
	cursor: pointer;
	transition: ease-in-out 0.2s;
	margin-right: 15px;
}
.star-selecao-automatica:hover {
	color: #fdd835;
}
.star-selecao-automatica.favoritado {
	color: #fdd835;
}
#menu-imagem-perfil {
	margin-left: 50px;
}
#menu-imagem-perfil a {
	text-decoration: none;
	color: #333;
}
.linha-selecao-automatica:hover {
	background: #eee;
}
.linha-selecao-automatica.selecionado {
	background-color: rgba(102, 39, 128, 0.1);
	color: #7501c0;
	font-weight: bold;
}
.linha-selecao-automatica {
	text-align: left;
	border-radius: 5px;
	padding: 10px;
	transition: ease-in-out 0.2s;
	cursor: pointer;
	margin-top: 5px;
	margin-bottom: 5px;
}

.segment-descricao .ui.segment {
	border-radius: 5px !important;
	box-shadow: 0 0px 0px 0 rgba(34, 36, 38, 0.15) !important;
}

.descricao-roteiro {
	box-shadow: 0px;
	/* border-top: 3px solid #662780 !important; */
	/* border: 3px; */
	/* box-shadow: 0px 0px 0px 1px rgba(34,36,38,.15); */
}
