$primaryColor: #7501c0;
$primaryColor25: rgba(79, 30, 99, 0.25);
$primaryColor10: rgba(79, 30, 99, 0.1);
$background: #ffffff;
$backgroundAccent: #eee;
$red: #f44336;
$blue: #1e88e5;
$green: #35be35;
$orange: #ff6f00;
$tablet-width: 768px;
$desktop-width: 1024px;

@import "Interno/Dashboard/Dashboard.scss";

.menu-sistema {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100vh;
	position: fixed;
	z-index: 999;
	background: $primaryColor;
	transition: ease-in-out all 0.3s;
	.menu-mobile {
		display: none;
	}
	.header-menu {
		width: 100%;
		height: auto;
		flex: 1;
		display: flex;
		overflow: hidden;
		justify-content: center;
		div:first-child {
			border-radius: 5px;
			padding: 10px;
			align-self: center;
			cursor: pointer;
			height: 45px;
			width: 45px;
			margin-top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 15px;
		}
		div:first-child:hover {
			background-color: #831bc6;
		}
		.menu-icon {
			color: #fafafa;
			margin: 0px;
		}
	}
	.menu-navegacao {
		display: flex;
		flex: 1;
		flex-direction: column;
		max-height: calc(100vh - 75px - 70px);
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
		.lista-menus {
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 100% !important;
			justify-content: center;
			height: 0;
			&.menu-atual {
				.label-icon {
					display: none !important;
				}
			}
			&:hover,
			&.menu-atual {
				background-color: $primaryColor;
				.menu-icon-center {
					background: rgba(255, 255, 255, 0.1);
					.square-icon {
						i.icon {
							color: #fafafa;
							opacity: 1;
						}
						.label-icon {
							display: flex;
							position: absolute;
						}
					}
				}
			}
			.menu-icon-center {
				position: absolute;
				display: inline;
				top: 50%;
				transform: translateY(-50%);
				min-width: 0;
				padding: 10px;
				border-radius: 5px;
				transition: all ease-in-out 0.2s;
				.square-icon {
					display: inline-block;
					overflow: visible;
					white-space: nowrap;
					.label-icon {
						display: none;
						transform: translateY(calc(-100% + 5px));
						margin-left: 70px;
					}
					i.icon {
						transition: all ease-in-out 0.2s;
						margin: 0;
						color: rgba(255, 255, 255, 0.5);
					}
				}
			}
		}
		.divider-menu:first-child {
			display: none;
		}
		div,
		span {
			color: #fff;
		}
	}
	.footer-menu {
		display: block;
		height: 0;
		bottom: 0;
		position: relative;
		padding-bottom: 100%;
		.sair-menu {
			display: inline-block;
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
		}
		.versao-menu {
			color: #a064b8;
			align-self: center;
			align-items: center;
			flex: 1;
			display: flex;
		}
	}
	&.aberto {
		max-width: 360px;
		width: 100%;
	}
	&.fechado {
		max-width: 75px;
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.relative-margin-left {
		.supbar {
			display: flex;
			flex: 1;
			justify-content: space-between;
			.ui.small.image {
				width: 100px !important;
			}
			.PrimaryAppBar-grow-25 {
				display: none;
			}
		}
	}

	.menu-mobile {
		width: 100%;
		height: auto;
		max-height: 71px;
		flex: 1;
		justify-content: center;
		display: flex !important;
		overflow: hidden;
		div:first-child {
			padding: 10px;
			align-self: center;
			cursor: pointer;
		}
		.menu-mobile-icon {
			color: #fafafa;
		}
	}

	.menu-sistema-fechado {
		height: 71px;
		.header-menu {
			display: none !important;
		}
		.menu-navegacao-fechado {
			display: none !important;
		}
		.footer-menu-fechado {
			display: none !important;
		}
	}

	.relative-margin-left {
		padding-left: 0px;
		.supbar {
			padding-left: 85px;
			width: 100%;
			.ui.small.image {
				width: 100px !important;
			}
		}
	}

	.relative-margin-left-fechado {
		padding-left: 0px;
		.supbar {
			padding-left: 85px;
			width: 100%;
			.ui.small.image {
				width: 100px !important;
			}
		}
	}
}

.ui.modal.modal-secoes {
	padding-right: 100px;
	padding-left: 100px;
	max-height: 800px;
	.lista-produtos {
		overflow-y: auto;
		max-height: 510px;
	}
	.adicionar-produtos {
		overflow-y: auto;
		max-height: 450px;
	}
	.header-secao {
		font-family: Roboto, Helvetica, Arial, sans-serif;
		text-align: left;
		font-size: 26px;
		font-weight: bold;
		display: flex;
		.input {
			max-width: 75%;
		}
		.botao-nome {
			background: #eee;
			padding: 10px;
			padding-top: 0px;
			border-radius: 5px;
			margin: 10px;
			transition: ease-in-out 0.2s;
			&:hover {
				background: #ccc;
			}
		}
		.div-header {
			flex: 1;
		}
		.ui.message {
			font-size: 14px;
			.content {
				font-weight: normal;
			}
			i {
				font-size: 1.6em;
			}
		}
	}
	.checkboxes .ui.checkbox {
		margin-right: 25px;
		input:checked {
			background-color: $primaryColor;
			~ label {
				transition: ease-in-out 0.1s;
				color: $primaryColor;
				font-weight: bold;
			}
			~ label:after {
				color: $primaryColor;
			}
		}
	}
	.produto-secao {
		background: #fafafa;
		margin-top: 10px;
		padding: 10px;
		border-radius: 5px;
		cursor: pointer;
		.sub {
			font-size: 12px;
			color: #666;
			span:first-child {
				margin-right: 10px;
			}
		}
		.nome {
			font-weight: bold;
		}
		&:hover {
			background: #eee;
		}
	}
	.ui.icon.left.action.input {
		button {
			border-radius: 0px 10px 10px 0px !important;
			box-shadow: none !important;
			background: $primaryColor25 !important;
			color: $primaryColor !important;
		}
		input {
			border-radius: 10px 0px 0px 10px !important;
			border: none;
			background: #fafafa;
		}
	}
	.cancelar-secao {
		display: inline-block;
		margin: 15px;
		color: $red;
		cursor: pointer;
	}
	.sem-produtos {
		width: 100%;
		background: #eee;
		text-align: center;
		line-height: 50px;
		padding: 30px;
		border-radius: 10px;
		font-size: 18px;
		font-weight: bold;
		color: #333;
		i {
			display: block;
			margin: auto;
		}
		span {
			font-size: 14px;
			font-weight: normal;
			display: block;
			line-height: 20px;
			color: #666;
		}
	}
}

.novo-roteiro {
	width: 60%;
}

@media only screen and (max-width: 600px) {
	.novo-roteiro {
		width: 100%;
	}
}
.secao-roteiro {
	background: #fafafa;
	margin: auto;
	position: relative;
	// width: 50%;
	border-radius: 5px;
	display: block;
	padding: 15px;
	margin-top: 10px;
	cursor: pointer;
	transition: ease-in-out 0.2s;
	&:hover {
		background: #eee;
	}
	.secao-titulo {
		font-weight: bold;
		font-size: 1.2em;
	}
	.secao-atividades {
		margin-top: 10px;
		color: #666;
	}
	.secao-n-produtos {
		background: $primaryColor10;
		display: inline-block;
		height: 0px;
		padding: 40px;
		border-radius: 50%;
		position: relative;
		margin: auto;
		.numero {
			color: $primaryColor;
			font-weight: bold;
			font-size: 1.2em;
			position: absolute;
			transform: translate(-50%, -50%);
		}
	}
	.secao-delete-button {
		margin: auto !important;
		background: #eee;
		&:hover {
			background: #f44336;
			color: #fff;
		}
	}
}

.ui.message.icon {
	box-shadow: none;
	border-radius: 10px;
}
.banner-roteiro + * {
	margin-top: 30px;
}
.banner-roteiro {
	.bg-teste {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;
		gap: 1rem;
		text-align: center;

		@media (min-width: 48em) {
			justify-content: space-between;
			flex-direction: row;
			align-items: normal;
			text-align: justify;
		}

		.banner-font {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1;

			.title {
				line-height: initial;
				justify-content: flex-start;
				color: $primaryColor;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 2rem;
			}
			.sub {
				font-size: 14px;
				display: inherit;
				font-weight: normal;
			}
		}
		.banner-info {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}
.circular-chart {
	display: block;
	font-size: 18px;
	margin-left: 5px;
	margin-right: 5px;
	.circle-bg {
		fill: none;
		stroke: #eee;
		stroke-width: 3.8;
	}
	.circle {
		fill: none;
		stroke-width: 2.8;
		stroke-linecap: round;
		animation: progress 1s ease-out forwards;
		&.purple {
			stroke: $primaryColor;
		}
		&.red {
			stroke: $red;
		}
		&.blue {
			stroke: $blue;
		}
		&.green {
			stroke: $green;
		}
		&.orange {
			stroke: $orange;
		}
	}
	.percentage {
		fill: #666;
		font-family: sans-serif;
		font-size: 0.5em;
		text-anchor: middle;
	}
	.graph-name {
		fill: #666;
		font-family: sans-serif;
		font-size: 0.25em;
		text-anchor: middle;
		font-weight: normal;
	}
}

@media (max-width: $desktop-width) {
	.ui.modal.modal-secoes {
		padding-right: 20px;
		padding-left: 20px;
		max-height: max-content;
	}
}

// Footer.js

.footer {
	text-align: center;
	line-height: 0px !important;
}

.div-imagem {
	padding: 20px;
}

// perfil.js

.banner-wrapper {
	position: relative;
	width: 100%;
	height: 160px;
	margin-top: -20px;
	border-bottom: 3px solid #4f1e63;
}

.banner-background {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("./imagens/BG1.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 0;
}

.banner-container {
	display: flex !important;
	flex-grow: 1;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	height: 100%;
}

.banner-usuario {
	position: relative;
	min-width: 25%;
	.foto-empresa {
		margin: 0;
		width: 64%;
		padding-bottom: 64%;
		margin-bottom: 25px;
	}
}

.banner-usuario-container {
	position: absolute;
	top: 15%;
	width: 100%;
	text-align: center;
}

.user-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 75%;
	align-items: flex-end;
	padding: 12px 0;
	z-index: 998;
}

.user-data {
	h4 {
		text-transform: "capitalize";
		font-weight: bold;
		margin-bottom: 0;
	}
}

.user-data {
	h5 {
		color: #999;
	}
}

// .user-attrs {}

.user-ratings {
	i {
		font-size: 1.5rem !important;
	}
}

.user-ratings {
	i.active {
		color: rgb(251, 210, 3) !important;
	}
}

.entry-label {
	margin-right: 10px;
	font-weight: bold;
}

.entry-text {
	line-height: 1.6rem;
	font-size: 18px;
	color: #999;
	margin-bottom: 20px !important;
}

@media (max-width: 548px) {
	.banner-wrapper {
		height: 280px !important;
	}
	.banner-container {
		padding: 20px 0;
		flex-direction: column !important;
	}

	.banner-usuario-container {
		position: relative;
		top: 0 !important;
	}

	.banner-usuario {
		.foto-empresa {
			width: 25% !important;
			padding-bottom: 25% !important;
		}
	}

	.user-tabs {
		width: 100% !important;
	}
}

@media (max-width: 1000px) {
	.banner-usuario-container {
		top: 35%;
	}
}

// agencia.css

.info-subconta {
	width: 100%;
	/*background: #662780;*/
	margin-top: -20px;
}
.saldo {
	font-size: 70px;
	padding: 10px;
	color: #fafafa;
	display: block;

	p:last-child {
		line-height: 70px;
		font-weight: bold;
	}

	p:first-child {
		font-size: 16px;
		line-height: 22px;
		text-transform: uppercase;
		padding-left: 5px;
	}
}

.titulo-dashboard {
	font-size: 22px;
	font-weight: bolder;
	line-height: 22px;
	color: #666;
	text-transform: uppercase;
	padding: 15px;
}

.saldo.inverted {
	color: #662780;
}

// filter.js

.form_filter {
	width: 100%;
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.button_group_filter {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 25.8px;
}

@media only screen and (max-width: 1200px) {
	.form_filter {
		flex-direction: column;
		justify-content: space-between;
	}
	.form_filter * {
		width: 100%;
	}
	.button_group_filter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		button {
			width: 48% !important;
		}
	}
}

// dashboard

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,900");

.foto-empresa {
	width: 100%;
	padding-bottom: 100%;
	background: #333;
	display: inline-block;
	margin: auto;
	background-position: center;
	background-size: cover;
}

.foto-empresa.circular {
	border-radius: 50%;
}

.foto-empresa-wall {
	width: 150px;
	height: 150px;
	border-radius: 150px;
	overflow: hidden;
	position: absolute;
	left: 50%;
	transform: translate(-50%, calc(-50% - 2rem));
}

.dashboard-dados-principais {
	background-image: linear-gradient(-87deg, #4f1e63 0%, #662780 100%);
	border-radius: 50px;
	width: 90%;
	margin: auto;
	padding: 2rem;
	margin-top: 90px;
}

.msg-boas-vindas {
	width: 100%;
	font-family:
		"Lato",
		"Open Sans",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		Arial,
		"Noto Sans",
		sans-serif,
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol",
		"Noto Color Emoji";
	font-size: 1.5rem;
	position: relative;
	display: inline-block;
	text-align: center;
	color: #666;
	font-style: italic;
}

.link-roteiro,
.link-roteiro:visited {
	color: black;
	margin: 5px;
}

.link-roteiro:hover,
.link-roteiro:active {
	color: #666 !important;
}

.a {
	color: #6c757d;
}

.dashboard-header {
	margin-bottom: 50px;
	color: #666;
	line-height: 2.4rem;
	font-size: 24px;
	font-style: italic;
}

.dashboard-header-acesso {
	margin-bottom: 20px;
	color: #666;
	line-height: 2.4rem;
	font-size: 30px;
	font-weight: bold;
}

.dashboard-fantasia {
	margin-top: 30px;
	color: #662780;
	font-size: 30px;
	font-weight: bold;
	line-height: 2.4rem;
}

.dashboard-email {
	color: #666;
	margin-bottom: 30px;
	font-size: 20px;
}

.dashboard-endereco-container {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
}

.dashboard-endereco {
	text-align: left;
	color: #666;
	line-height: 2.4rem;
	font-size: 24px;
}

.dashboard-button {
	color: #fff !important;
	padding: 20px 0 !important;
	margin-bottom: 20px !important;
	font-size: 20px !important;
}

// feedback.js

.div-feedback {
	font-weight: bold;
	display: flex;
	justify-content: center;
	input[type="radio"] {
		margin-right: 12px;
	}
	.ui.large.form {
		font-size: 16px;
	}
	.required {
		field {
			font-size: 32px;
		}
	}
	.inline.fields {
		padding-bottom: 10px;
		padding-top: 10px;
	}
	.ui.form {
		.field > label {
			margin: 15px;
			font-size: 18px;
		}
	}
	label {
		font-size: 30px;
	}
}

input[type="checkbox"] {
	margin-right: 12px;
}

// NovoProduto.js

#arquivo-input {
	display: none;
}

.produto-foto-adicionada {
	cursor: pointer;
	transition: ease-in-out all 0.3s;
}

.produto-foto-adicionada:hover {
	opacity: 0.8;
}

.ui.modal.ver-planograma {
	.header {
		text-align: center;
		border-bottom: none;
		padding-bottom: 0 !important;
	}
	.content {
		display: flex;
		height: calc(100% - 43px);
		flex-direction: column;
		justify-content: space-between;
	}
}

.ui.basic.button.red.no-border,
.ui.basic.button.grey.no-border,
.ui.basic.button.red.no-border:hover,
.ui.basic.button.grey.no-border:hover {
	box-shadow: none !important;
}

// pagamentos.js

.carrinho-pagamento {
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	color: #666;
	.total-carrinho {
		border-top: 3px solid #4f1e63;
		margin-top: 15px;
		padding-top: 10px;
	}
}

.info-treinamento {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	align-content: center;
	.info-ativar {
		align-items: center;
		flex: 1;
		font-size: 18px;
		display: flex;
		.toggle label {
			margin-bottom: 0;
		}
		.ativo-label {
			display: flex;
			margin-left: 10px;
		}
	}
	.info-nota {
		text-align: right;
		font-size: 16px;
		flex: 1;
		vertical-align: middle;
		div {
			font-weight: bold;
			font-size: 24px;
		}
	}
}

.atividade-treinamento {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
	border-radius: 3px;
	cursor: pointer;
	.info-atividade-treinamento {
		flex: 1;
		display: flex;
		align-items: center;
		flex-direction: row;
		.icon-drag-indicator {
			display: flex;
			flex-direction: row;
		}
		.icon-drag-indicator div {
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: rgb(92, 92, 92);
			margin: 1px;
		}
		.icon-drag-indicator.active div {
			background-color: #662780;
		}
		div {
			// flex: 1;
			padding-left: 5px;
			.titulo {
				display: block;
				font-weight: bold;
				font-size: 16px;
			}
			.tipo {
				display: block;
				font-size: 14px;
			}
		}
	}
	&:hover {
		background: #fafafa;
	}
	&:last-child {
		border-bottom: 0;
	}
}
.descricao-adicionar-atividade {
	font-size: 18px;
	color: grey;
	line-height: 27px;
	margin-bottom: 50px;
}
.tipo-atividade {
	.tipo-atividade-icone {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		background: $primaryColor25;
		padding: 20px;
		border-radius: 5px;
		i {
			color: $primaryColor;
		}
	}
	.tipo-atividade-descricao {
		font-size: 16px;
		line-height: 24px;
		color: grey;
		text-align: center;
	}
	.button {
		margin-top: 20px;
	}
}
.button-wrapper {
	position: relative;
	width: 100%;
	.label-treinamento-imagem {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 200px;
		background: #eeeeee;
		cursor: pointer;
		color: rgb(156, 156, 156);
		text-transform: uppercase;
		font-size: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	#upload {
		display: inline-block;
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 200px;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
}
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
	background-color: $primaryColor !important;
}

body .ui.toggle.checkbox input:checked ~ .box:before,
body .ui.toggle.checkbox input:checked ~ label:before {
	background-color: $primaryColor !important;
}

.treinamento-oportunidade {
	padding: 5px 10px 5px 10px;
	border-radius: 3px;
	background: #fafafa;
	margin: 5px;
	line-height: 18px;
	transition: ease-in-out 0.1s;
	cursor: pointer;
	p {
		&:first-child {
			font-weight: bold;
			font-size: 16px;
			color: #333;
		}
		&:last-child {
			font-size: 14px;
			color: #999;
			margin-top: -10px;
		}
	}
	&:hover {
		background: #eee;
	}
}

.header-financeiro {
	display: flex;
	flex: 1;
	flex-direction: row;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #eee;
	align-items: center;
	.saldo-financeiro,
	.saldo-reservado {
		font-weight: bold;
		font-size: 16px;
		margin-right: 10px;
		span {
			font-weight: normal;
			font-size: 14px;
		}
	}
	.saldo-reservado {
		margin-left: 20px;
	}
}

.botoes-secao {
	// display: flex;
	background-color: #fff;
	width: 100%;
	// justify-content: flex-end !important;
	.ui.green.button {
		.check {
			display: inline-block;
		}
	}
}

.container-header-padrao {
	height: 47px;
	display: flex;
	flex-direction: row-reverse;
}

.container-header-relatorio {
	display: flex;
	flex-direction: row;
}
.container-header-desc {
	width: 50%;
	margin-bottom: 10px;
}

@media only screen and (max-width: 795px) {
	.container-header-padrao {
		height: auto;
		flex-direction: column-reverse;
	}

	.container-header-padrao a {
		width: 100% !important;
	}

	.container-header-padrao button {
		width: 100% !important;
	}

	.container-header-relatorio {
		flex-direction: column;
	}
	.tab-bar-wrapped {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.buttons-in-column {
		display: flex;
		flex-direction: column;
	}

	.container-header-desc {
		width: 100%;
	}

	.padding-padrao-segment {
		padding: 1em;
		margin-bottom: 10px !important;
	}
}

@media only screen and (max-width: 1200px) {
	.container-header-padrao a {
		width: 250px;
	}

	.container-header-padrao button {
		width: 250px;
	}
}

.roteiro-form {
	width: 100%;
}

@media only screen and (min-width: 800px) {
	// and (max-width: 1199)
	.form-produto {
		.ui.grid {
			margin-top: 0;
		}
	}
}

.download-exemplo-CSV {
	width: 150px;
}

.importar-csv-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}

@media only screen and (max-width: 790px) {
	.importar-csv-header {
		flex-direction: column;
	}
}

#jsd-widget {
	min-height: 0;
	box-shadow: none;
	z-index: 10 !important;
}

.fotoTabela {
	display: flex !important;
	justify-content: center;
}
.card-perfil {
	padding: 15px;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	width: 195px;
	background-color: #fafafa;
	color: rgba(0, 0, 0, 0.54);
	border-radius: 3px;
	position: absolute;
	left: 66px;
	top: 69px;
	box-shadow:
		0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14),
		0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	.usuario {
		color: #333 !important;
	}
}
