.dashboard{
    .dash-title{
        display: block;
        font-weight: 900;
        font-size: 32px;
        color: #000;
        margin-bottom: 5px;
    }
    @media only screen and (max-width: 1279px){
        .dashboard-box-right{
            display: none;
        }
    }
    .dashboard.loader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .dashboard-box-right{
        width: 500px;
        padding: 15px;
        border-left: 1px solid #eee;
        .header-box-right{
            display: flex;
            flex-direction: row;
            align-items: center;
            .box-icon:hover{
                cursor: pointer;
                background: #eee;
            }
            .dash-title{
                flex: 1;
                font-size: 22px;
                text-align: center;
                color: #666;
                margin: 0;
            }
        }
        .box-icon{
            padding: 10px;
            font-size: 18px;
            align-items: center;
            border-radius: 5px;
            i.icon{
                color: #666;
                margin: 0;
            }
        }
        .box-right{
            display: flex;
            flex: 1;
            flex-direction: column;
            .promotor-nome{
                font-weight: 900;
                font-size: 22px;
                color: #666;
                text-align: center;
                padding: 10px;
            }
            .oportunidade-status{
                display: inline-block;
                padding: 5px 15px;
                border-radius: 5px;
                margin: 5px 0px;
                color: #fafafa;
                font-weight: 500;
                text-align: center;
                justify-self: center;
                font-size: 12px;
                &.s1{
                    background: #666;
                }
                &.s2{
                    background: $orange;
                }
                &.s3{
                    background: $green;
                }
                &.s5{
                    background: $green;
                }
                &.s6{
                    background: $blue;
                }
                &.s7{
                    background: $red;
                }
            }
            .pdv-info{
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                padding: 15px 0px;
                .pdv-data{
                    display: flex;
                    flex-direction: column;
                    font-weight: 700;
                    color: #333;
                    padding: 0px 10px;
                    span:last-child{
                        color: #666;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            .secao{
                display: flex;
                flex-direction: column;
                border: 1px solid #eee;
                border-radius: 5px;
                transition: ease-in-out 0.3s;
                margin-bottom: 5px;
                .head-secao{
                    font-size: 16px;
                    font-weight: 700;
                    color: #333;
                    display: flex;
                    flex-direction: row;
                    padding: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    align-items: center;
                    &:hover{
                        background: #f6f6f6;
                    }
                    div:first-child{
                        flex: 1
                    }
                    .atividades{
                        display: flex;
                        flex-direction: row;
                        .box-icon{
                            font-size: 14px;
                            padding: 5px;
                        }
                    }
                }
                .body-secao{
                    .head-table-produtos{
                        display: flex;
                        flex-direction: row;
                        font-size: 14px;
                        color:#999; 
                        padding: 10px 15px;
                        border-top: 1px solid #eee;
                        border-bottom: 1px solid #eee;
                        span:first-child{
                            display: flex;
                            flex: 1;
                        }
                    }
                    .produto{
                        display: flex;
                        flex-direction: row;
                        padding: 10px 15px;
                        font-size: 14px;
                        color: #999;
                        font-weight: 700;
                        div:first-child{
                            display: flex;
                            flex:1;
                        }
                    }
                }

            }
        }
    }
    .sem-dados{
        font-size: 18px;
        color: #666;
        padding: 20px;
        background: #f6f6f6;
        border-radius: 5px;
    }
    .dashboard-box{
        flex: 1;
        padding: 10px;
        .dash-saldos{
            display: flex;
            flex: 1;
            flex-direction: row;
            // justify-content: flex-end;
            .dash-infosaldo{
                display: flex;
                position: relative;
                flex-direction: column;
                padding: 10px 0px 10px 0px;
                padding-right: 30px;
                .titulo-infosaldo{
                    font-size: 12px;
                    font-weight: 300;
                    color: #666;
                    text-transform: capitalize;
                }
                .valor-infosaldo{
                    font-size: 24px;
                    font-weight: 900;
                    color: #444;
                }
            }
        }
        .dash-legenda{
            .legenda{
                font-size: 14px;
                font-weight: 400;
                color: #666;
                .box-cor{
                    background: #ccc;
                    width: 15px;
                    height: 15px;
                    border-radius: 3px;
                    margin-left: 15px;
                    margin-right: 5px;
                    &.blue{
                        background: $blue;
                    }
                    &.red{
                        background: $red;
                    }
                    &.orange{
                        background: $orange;
                    }
                    &.green{
                        background: $green;
                    }
                }
            }
        }
        .dash-dias-semana{
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 20px;
            // overflow-x: scroll;
            .dia-semana{
                display: flex;
                flex-direction: column;
                width: 110px;
                height: 170px;
                border-radius: 5px;
                font-size: 14px;
                color: #666;
                text-align: center;
                background: #f9f9f9;
                margin-right: 5px;
                transition: ease-in-out all 0.3s;
                cursor: pointer;
                .dia-titulo{
                    font-weight: 700;
                    padding: 10px;
                    text-transform: capitalize;
                }
                .dia-dados{
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    .chart-text {
                        font: 20px/1.4em "Montserrat", Arial, sans-serif;
                        fill: #000;
                        -moz-transform: translateY(0.25em);
                        -ms-transform: translateY(0.25em);
                        -webkit-transform: translateY(0.25em);
                        transform: translateY(0.25em);
                    }
                    
                    .chart-number {
                        font-size: 0.6em;
                        line-height: 1;
                        text-anchor: middle;
                        -moz-transform: translateY(-0.25em);
                        -ms-transform: translateY(-0.25em);
                        -webkit-transform: translateY(-0.25em);
                        transform: translateY(-0.25em);
                    }
                    
                    .chart-label {
                        font-size: 0.2em;
                        text-transform: uppercase;
                        text-anchor: middle;
                        -moz-transform: translateY(0.7em);
                        -ms-transform: translateY(0.7em);
                        -webkit-transform: translateY(0.7em);
                        transform: translateY(0.7em);
                    }
                }
                .dia-valor{
                    display: block;
                    padding: 10px;
                }
                &.atual{
                    width: 150px;
                    height: 220px;
                    box-shadow: 0px 0px 20px #ddd;
                    .dia-titulo{
                        font-size: 16px;
                    }
                    .dia-valor{
                        font-size: 16px;
                        font-weight: 500;
                        span{
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                }
            }
            @media only screen and (min-width: 1367px){
                .dia-semana{
                    width: 130px;
                    &.atual{
                        width: 180px;
                    }
                }
            }
        }
        .dash-detalhes-dia{
            display: flex;
            flex: 1;
            flex-direction: row;
            .dia-oportunidades{
                display: flex;
                flex: 1;
                margin: 5px;
                flex-direction: column;
                .detalhes-dia{
                    padding: 15px 0px;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    .oportunidade-info{
                        width: 100%;
                        padding: 15px;
                        border: 1px solid #f6f6f6;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        transition: all 0.3s;
                        div{
                            padding: 0px 15px;
                        }
                        .box-icon{
                            padding:10px;
                            border-radius: 5px;
                            display: inline;
                            margin-right: 10px;
                            i.icon{
                                font-size: 20px;
                                margin:0;
                                color: #666;
                                &.check{
                                    color: $green;
                                }
                                &.clock{
                                    color: $orange
                                }
                                &.fire{
                                    color: $blue
                                }
                                &.calendar.times.outline{
                                    color: $red
                                }
                            }
                        }
                        .pdv, .secoes, .info-promotor, .tipo, .tipo-sem-candidatos{
                            display: flex;
                            flex-direction: column;
                            span{
                                font-size: 16px;
                                color: #333;
                                font-weight: 700;
                            }
                            span:last-child{
                                font-size: 14px;
                                color: #999;
                                font-weight: 400;
                                i.icon{
                                    font-size: 14px;
                                    margin: 0px;
                                }
                            }
                        }
                        .pdv{
                            flex: 1;
                        }
                        .promotor{
                            display: flex;
                            flex-direction: row;
                        }
                        .secoes, .tipo{
                            text-align: center;
                        }
                        .price{
                            font-size: 20px;
                            font-weight: 900;
                            color: #333;
                        }
                        &:hover{
                            background: #f6f6f6;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 949px){
    .dashboard{
        display: flex;
        flex-direction: row;
        .dashboard-box {
            padding: 15px;
            // .dash-dias-semana {
            //     // height: 220px;
            // }
            .dash-legenda {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                .legenda {
                    display:flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
            .dash-div-title {
                display: flex;
                flex-direction: row;
                align-items: center;
            } 
            .dash-saldos {
                justify-content: flex-end;
            }
        }
        .oportunidade-info {
            &.now{
                box-shadow: 0px 0px 20px #ddd;
            }
        }
        .detalhes-dia {
            .oportunidade-info {
                .box-icon {
                    background-color: #f6f6f6;
                }
            }
        }
    }
}

@media only screen and (max-width: 945px) {
    .dashboard {
        .dashboard-box {
            .dash-dias-semana {
                overflow-x: scroll;
            }
            .dash-legenda {
                .legenda {
                    display: inline-flex;
                }
            }
        }
    }
}

// @media only screen and (min-width: 1024px) {
//     .dashboard {
//         .dashboard-box {
//             .dash-dias-semana {
//                 overflow-x: hidden;
//             }
//         }
//     }
// }

@media only screen and (max-width: 685px) {
    .dashboard {
        .dashboard-box {
            // .dash-legenda{
            //     .legenda {
            //         display: inline-flex;
            //     }
            // }
            .dash-title {
                text-align: center;
            }
            .dash-saldos {
                text-align: center;
                .dash-infosaldo {
                    width: 100%;
                    padding-right: 0px;
                }
            }
            .dash-detalhes-dia {
                .dia-oportunidades {
                    .detalhes-dia {
                        .oportunidade-info {
                            .box-icon {
                                display: flex;
                                justify-content: center;
                            }
                            .pdv {
                                text-align: center;
                            }
                            .promotor {
                                padding: 5px 0 5px 0;
                                .info-promotor {
                                    .info-promotor-stars {
                                        text-align: center;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                            .tipo-sem-candidatos {
                                padding: 5px 0 5px 0;
                                text-align: center;
                            }
                            .tipo {
                                display: inline-flex;
                                width: 50%;
                                padding: 5px 0 5px 0;
                            }
                            .secoes {
                                display: inline-flex;
                                width: 50%;
                                padding: 5px 0 5px 0;
                            }
                            .price {
                                padding: 5px 0 5px 0;
                                text-align: center;
                            }

                        } 
                    } 
                } 
            }
        }
    }
}

@media only screen and (min-width: 685px) {
    .oportunidade-info {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
    }
}