.container-mes-anterios {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.card-ruptura{
    min-width: 250px;
    max-width: 250px;
    height: 150px;
    border-radius: 10px;
    color: #fff;
}

.body-card-ruptura {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px
}

.card-ruptura:first-of-type{
    margin-right: 10px;
}
.link-oportunidade-lista{
  color: #272727 
}
.link-oportunidade-lista:hover{
  color: rgb(141, 141, 141);
}

.card-ruptura-icon {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-ruptura-icon span{
    font-size: 25px
}

.quiz-graph {
    /* height: 250px; */
    width: 100%;
}
.linha-card{
    width: 100%;
}

.tabela-de-rupturas{ 
  width: 70%; 
}
.top-user-ruptura{ 
  width: 30%; 
  padding-top: 44px
}

@media only screen and (max-width: 1000px) {
  .tabela-de-rupturas{ 
    width: 100%;
  }
  .top-user-ruptura{ 
    width: 100%; 
  }
}

/* top usuario ruptura */

.container-top{
  display: flex;
  flex-direction: row;
}
.body-top {
  width: 80%;
  margin-left: 10px
}
.header-top{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-top p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-table-label{ 
  display: none;
}

@media only screen and (max-width: 760px) {
  .row-table-label{ 
    display: flex;
    font-weight: bold;
    margin-right: 5px;
  }
}