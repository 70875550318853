.download-table-xls-button {
	background-color: #7501c0;
	border: none;
	border-radius: 3px;
	color: white;
	padding: 8px 20px;
	width: 137.83px;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
}
