.relatorio-filtro {
    width: 100%;
    display: flex; 
    align-items: flex-end;
}

/* .relatorio-container-filtro {
    width: 375px
} */

.relatorio-container-input {
    width: 50%; 
    display: flex; 
    flex-direction: column; 
    margin-right: 10px
}

.relatorio-enunciado{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .relatorio-container-filtro {
        width: 320px
    }
    .relatorio-container-input {
        margin: 0px
    }
    .relatorio-enunciado{
        max-width: 350px;
    }
    .relatorio-filtro {
        flex-wrap: wrap;
    }

    .relatorio-filtro button{
        width: 100%;
        margin: 0px !important;
        margin-top: 10px !important;
    }
}
