.Formulario-Recorrencia > * {
    margin-bottom: 5px;
    margin-top: 5px;
}

/* The animation code */
@keyframes frame {
    0%   {background-color: #eee;}
    25%  {background-color: rgb(206, 205, 205);}
    50%  {background-color: rgb(180, 180, 180);}
    100% {background-color: #eee;}
}
  
/* The element to apply the animation to */
.placeholder-animado {
    background-color: #eee;
    opacity: 0.2;
    animation-name: frame;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

.info-recorrencia label {
    font-weight: bold;
}
