@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400");
.imagem-circular {
	border-radius: 50%;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin: auto;
	transition: ease-in-out all 0.3s;
}

.grande-1 {
	width: 100px;
	height: 100px;
}

.grande {
	width: 100px;
	height: 100px;
}

.extra-grande {
	width: 180px;
	height: 180px;
}

.medio-1 {
	width: 80px;
	height: 80px;
}

.medio {
	width: 50px;
	height: 50px;
}

.m-medio {
	width: 40px;
	height: 40px;
}

.pequeno {
	width: 30px;
	height: 30px;
}

.pequeno-1 {
	width: 15px;
	height: 15px;
}

.notificacao {
	width: 100%;
	max-width: 360px;
	height: 100vh;
	position: fixed;
	z-index: 999;
	right: 0px;
	overflow: hidden;
	overflow-y: scroll;
	background: #7501c0;
}

.remover-notificacao {
	cursor: pointer;
	z-index: 501;
	color: #ccc;
	font-size: 14px;
	transition: all ease-in-out 0.2s;
}

.remover-notificacao:hover {
	color: #f44336;
}
/* 
@media (min-width: 600px) {
    .notificacao {
        height: 88vh;
    }
} */

/* CARTÃO DE CREDITO TEMPLATE */

@import url("https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i");
.credit__card {
	font-family: "Space Mono", monospace;
	width: 320px;
	height: 190px;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	perspective: 600px;
	position: relative;
	margin: auto;
	margin-bottom: 20px;
}

.card__part {
	top: 0;
	position: absolute;
	z-index: 1000;
	left: 0;
	display: inline-block;
	width: 320px;
	height: 190px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 8px;
	-webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
}

.card__front {
	padding: 18px;
	-webkit-transform: rotateY(0);
	-moz-transform: rotateY(0);
}

.card__back {
	padding: 18px 0;
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
}

.card__black-line {
	margin-top: 5px;
	height: 38px;
	background-color: #303030;
}

.card__logo {
	max-height: 30px;
	max-width: 55px;
}

.card__front-logo {
	position: absolute;
	top: 18px;
	right: 18px;
}

.card__square {
	border-radius: 5px;
	height: 30px;
}

.card_numer {
	display: block;
	width: 100%;
	word-spacing: 4px;
	font-size: 20px;
	letter-spacing: 2px;
	color: #fff;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
}

.card__space-75 {
	width: 75%;
	float: left;
}

.card__space-25 {
	width: 25%;
	float: left;
}

.card__label {
	font-size: 10px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.8);
	letter-spacing: 1px;
}

.card__info {
	margin-bottom: 0;
	margin-top: 5px;
	font-size: 16px;
	line-height: 18px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.card__name {
	font-size: 15px;
}

.card__back-content {
	padding: 15px 15px 0;
}

.card__secret--last {
	color: #303030;
	text-align: right;
	margin: 0;
	font-size: 14px;
}

.card__secret {
	padding: 5px 12px;
	background-color: #fff;
	position: relative;
}

.card__secret:before {
	content: "";
	position: absolute;
	top: -3px;
	left: -3px;
	height: calc(100% + 6px);
	width: calc(100% - 42px);
	border-radius: 4px;
	background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.card__back-logo {
	position: absolute;
	bottom: 15px;
	right: 15px;
}

.card__back-square {
	position: absolute;
	bottom: 15px;
	left: 15px;
}

.credit__card:hover .card__front,
.card__hover .card__front {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}

.credit__card:hover .card__back,
.card__hover .card__back {
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
}

.botao-aprovar-candidato {
	text-align: center;
	background-image: linear-gradient(-87deg, #4f1e63 0%, #662780 100%) !important;
	color: #fafafa !important;
	cursor: pointer;
}

.fantasia-empresa-menu {
	font-family: "Lato", sans-serif;
	font-weight: bold;
	color: #a064b8;
	font-size: 1.5em;
	align-self: center;
}

.fantasia-empresa-menu span {
	font-size: 0.7em;
	font-weight: normal;
	display: inline-block;
	color: #eee;
}

.card-aprovar-candidato.ui.card > :first-child {
	border-radius: 10px !important;
}

.card-aprovar-candidato-hover {
	opacity: 0;
	display: block;
	position: absolute;
	transition: ease-in-out 0.3s;
	width: 100%;
	height: 100%;
}

.card-aprovar-candidato:hover .card-aprovar-candidato-hover {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1;
}
.card-aprovar-candidato-check {
	cursor: pointer;
	width: 50px;
	height: 50px;
	position: relative;
	margin: auto;
	background: #35be35;
	border-radius: 50px;
	top: 50%;
	transform: translateY(-90%);
}

.card-aprovar-candidato-check::before {
	content: "\F00C";
	color: #fafafa;
	font-size: 24px;
	line-height: 73px;
	text-align: center;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	width: 73px;
	height: 73px;
	background-color: rgba(53, 190, 53, 0.6);
	display: block;
	border-radius: 70px;
	transform: translate(-15%, -15%);
}

.card-aprovar-candidato-check::after {
	content: "Escolher";
	margin: auto;
	width: auto;
	background: #35be35;
	color: #fafafa;
	padding: 8px 18px;
	word-wrap: normal;
	border-radius: 5px;
	font-size: 15px;
	font-weight: bold;
	display: inline-block;
	left: 50%;
	position: relative;
	transform: translateX(-50%);
}
.ui.button.card-aprovar-candidato-hover-perfil-botao {
	background-image: none !important;
	position: absolute;
	display: inline-block;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
}

.ui.button.card-favoritar-candidato-hover-perfil-botao {
	background-image: none !important;
	position: absolute;
	display: inline-block;
	bottom: 70px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
}

.ui.modal.adicionar-documento,
.ui.modal.comunicar-interessados {
	border-radius: 30px;
	overflow: hidden;
	width: 600px;
	height: auto;
}

.ui.modal.comunicar-interessados > .content label {
	text-align: left;
	color: #333;
}

.ui.modal.comunicar-interessados > .content p {
	font-size: 15px;
	line-height: 1.8rem;
	margin-bottom: 10px;
	color: #666;
}
.ui.modal.adicionar-documento > .content {
	text-align: center;
}

.ui.form.raio-mapa .field > label {
	color: #fff;
}

.verificacao-email {
	height: 46px;
	background-color: #1e88f5;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.verificacao-email p {
	color: #fff;
	font-weight: bold;
}

.search-promoter {
	border: 1px solid rgb(255, 255, 255) !important;
}

.inputError {
	background-color: #e9effe !important;
	border: 2px solid #662780 !important;
}
i.credit.card.outline.icon {
	border: none !important;
	background: none;
}
i.credit.card.icon {
	border: none !important;
	background: none;
}

.search-promoter:focus {
	border: 2px solid #662780 !important;
}

.button-financeiro div {
	display: inline-block;
	margin-left: 10px;
}

.button-financeiro {
	border-bottom: 1px solid #eee;
	transition: ease-in-out 0.2s;
	cursor: pointer;
	color: #4f1e63;
	font-weight: bold;
}
.button-financeiro:hover {
	background: #fafafa;
}

.ui.search.pesquisaPromotor .input {
	width: 100%;
}

.ui.search.pesquisaPromotor .input input {
	border: 2 solid #333 !important;
}

.ui.modal {
	padding: 0;
	border-radius: 10px !important;
	overflow: hidden;
	z-index: 100000;
	left: 50%;
	top: 50%;
	height: auto;
	transform: translate(-50%, -50%);
}

.ui.modal > .header {
	text-align: center;
	border-bottom: none !important;
	padding-bottom: 0 !important;
}

.ui.modal.conta-invalida > .content {
	padding: 20px 0;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
}

.enviar-nota-fiscal-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	text-align: center;
}

.ui.form .field.input-banco-border {
	margin: 0 !important;
}

.input-banco-border {
	border: 1px solid #662780 !important;
}

.ui.modal.relancamento-roteiro {
	width: 600px;
	height: 320px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 30px;
}

.ui.modal.relancamento-roteiro > .header {
	text-align: center;
	border-bottom: none;
	padding-bottom: 0 !important;
}

.ui.modal.relancamento-roteiro > .content {
	padding: 20px 0;
	display: flex;
	height: calc(100% - 42px);
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
}

.relancar-roteiro-loader {
	color: #ff6f00 !important;
}

.relancar-roteiro-loader:after {
	border-top-color: #ff6f00 !important;
}

.ui.button.escolher-cadastro-botao {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: none !important;
	width: 500px;
	padding: 30px 0 !important;
}
.check-box-produtos label {
	word-wrap: break-word;
	word-break: break-all;
}
.check-box-produtos {
	width: 33.3% !important;
	display: flex;
	height: 30px;
}

@media (max-width: 850px) {
	.check-box-produtos {
		padding-bottom: 10px !important;
	}
}

.containerTabelaVazia {
	width: 100%;
	height: 450px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #ededed;
	border-radius: 10px;
	margin-top: -15px;
	transition: ease-in-out all 0.3s;
}

.containerTabelaVazia:hover {
	cursor: pointer;
	border: 1px solid #e1ff00;
}

.containerTabelaVazia:hover i {
	color: #7501c0 !important;
}

.containerTabelaVazia:hover p {
	color: #7501c0 !important;
}

.containerTabelaVazia svg {
	font-size: 10em !important;
	color: #666 !important;
	transition: ease-in-out all 0.3s;
}

.containerTabelaVazia p {
	font-size: 2em !important;
	color: #666 !important;
	transition: ease-in-out all 0.3s;
}

/* .ReactVirtualized__Grid__innerScrollContainer {
    max-width: none !important;
    display: flex;
    flex-direction: row;
} */

.botaoListaOportunidades {
	border: none !important;
	padding: none !important;
	margin: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

.botaoListaOportunidades:hover {
	background-color: transparent !important;
	background: transparent !important;
}
.scrollbarCardPromotor {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 190px;
}

@media only screen and (max-width: 600px) {
	.scrollbarCardPromotor {
		height: 20.5em;
	}
}

.row-table-label {
	display: none;
}

@media only screen and (max-width: 760px) {
	.row-table-label {
		display: flex;
		font-weight: bold;
		margin-right: 5px;
	}
}
.abrir-menu {
	color: #fff;
}
