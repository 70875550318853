.button-wrapper {
    position: relative;
    width: 100%;
}

.selecao-de-video {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 200px;
    background: #eeeeee;
    cursor: pointer;
    color: rgb(156, 156, 156);
    text-transform:uppercase;
    font-size:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  #upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 200px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }

  .carregando_video {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #4F1E63; /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }