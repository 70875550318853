.login {
	padding: 6% 2rem 1rem 2rem;
	margin: 0 auto 2rem auto;
	overflow: hidden;
}
.login-content {
	width: 430px;
	margin: auto;
}
@media (max-width: 600px) {
	.login-content {
		width: 100%;
		margin: auto;
	}
}
.login-logo {
	text-align: center;
	margin: 0 auto 3rem auto;
}
.login-head {
	margin-top: 0.8rem;
	color: #9e97aa;
	text-align: center;
	font-size: 1.3rem;
}
.login-sub-head {
	margin-top: 1rem;
	color: #9e97aa;
	text-align: center;
	font-size: 0.9rem;
}
.login-form {
	margin: 1rem auto;
	color: #aba5b6;
}
.login-group {
	padding: 0;
	margin: 0 auto;
	margin-top: 1.3rem;
}
.login-group input {
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: 16px 18px;
	background-position: 98% 50%;
	cursor: auto;
	border-radius: 40px;
	border: none;
	padding: 1.3rem 1.3rem;
	color: #91899f;
	background: #f7f6f9;
	font-family: sans-serif, Arial;
	display: block;
	width: 100%;
	font-size: 0.9rem;
	line-height: 1.25;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	outline: none;
}
.login-group input:focus {
	background: #eeeeee;
}
.login-sub-form {
	position: relative;
	padding-left: 7px;
	padding-right: 7px;
	margin: 15px auto;
	display: inline-block;
	width: 100%;
}
.login-sub-form a {
	text-decoration: none;
	font-weight: bold;
	font-size: 0.9rem;
	color: #aba5b6;
	cursor: pointer;
	float: right;
}
.login-botoes {
	text-align: center;
}
.login-botoes button {
	padding: 1.1rem;
	font-weight: normal;
	border-radius: 60px;
	width: 95%;
}
.login-nova-conta,
.login-group label {
	margin: 2rem auto;
	font-size: 1rem;
	color: #aba5b6;
	text-align: center;
}
a {
	cursor: pointer;
	text-decoration: none;
	color: #1de9b6;
}
.login-session-title {
	margin: 3.5rem auto;
	font-size: 1rem;
	color: #662780;
	text-align: left;
}
.loading-button-register {
	position: relative;
	top: -50%;
}
.botao-cadastre-se {
	padding: 10px;
	background: #662780;
	color: #fafafa;
	border-radius: 5px;
	opacity: 0.5;
}
.erro-login {
	padding: 10px;
	background: rgba(244, 67, 54, 0.4);
	border: 1px solid #f44336;
	margin-top: 10px;
	border-radius: 50px;
}
.logo-empresa-img {
	width: 250px;
	margin: auto;
}
.botao-agencia-empresa:hover {
	background: #eee;
}
.botao-agencia-empresa {
	width: 430px;
	padding: 40px;
	color: #666;
	margin: auto;
	position: relative;
	cursor: pointer;
	transition: all ease-in-out 0.3s;
}

.ui.fluid.dropdown.dropdown-cadastro-empresa {
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: 16px 18px;
	background-position: 98% 50%;
	cursor: auto;
	border-radius: 28px !important;
	border: none;
	padding: 1.3rem;
	color: #4b4e53;
	background: #e9e9e9;
	display: block;
	width: 100%;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.25;
	-webkit-transition:
		border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	-webkit-transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	outline: none;
}
