.btn-tipo-oportunidade {
	width: 100px;
	height: 100px;
	background: #fafafa;
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	outline: none;
	cursor: pointer;
	border: none;
	transition: all ease-in-out 0.2s;
	color: #999;
}
.btn-tipo-oportunidade:hover {
	font-weight: bold;
	background: #eee;
	color: #666;
}

.candidato-para-aprovar a {
	color: inherit;
}

.candidato-para-aprovar a:hover {
	color: inherit;
}

.dados-loja-cadastro :first-child {
	font-weight: bold;
	font-size: 1.5em;
	color: #fff;
}

.dados-loja-cadastro {
	margin-bottom: 20px;
	color: #eee;
	font-size: 14px;
}

.atividade-descricao {
	display: flex;
	flex-direction: row;
	padding: 9px 15px;
	margin-bottom: 5px;
	color: #fff;
	background: rgba(255, 255, 255, 0.2);
	margin-top: 5px !important;
	border-radius: 5px;
	transition: all ease-in-out 0.2s;
}

.atividade-descricao > span {
	margin-right: 15px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	flex-grow: 1;
	text-overflow: ellipsis;
}

.atividade-descricao-hover,
.atividade-descricao:hover {
	color: #666;
	background: #fafafa;
	cursor: pointer;
}

.atividade-descricao-hover .atividade-excluir {
	display: block !important;
}

.atividade-excluir:hover {
	color: #f44336;
}

.atividade-excluir {
	float: right;
	display: none !important;
}

.roteiro-info-secondary {
	font-size: 0.8em;
	color: #999;
	font-weight: normal;
	word-wrap: none;
	white-space: nowrap;
}

.roteiro-grid div {
	overflow: hidden !important;
	text-overflow: ellipsis;
}

.roteiro-grid {
	font-size: 16px;
	font-weight: bold;
}

.roteiro-valor {
	text-align: right;
	font-size: 1.2em;
	line-height: inherit;
}

.align-center {
	text-align: center;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.align-right {
	text-align: right;
}

.nome-candidato {
	font-weight: bold;
	font-size: 1.5em;
	vertical-align: center;
}

.subinformation-candidato {
	color: #999;
}

.roteiro-valor-reembolsado {
	text-decoration: line-through;
}

.center-v {
	align-self: center;
	justify-self: center;
	display: flex;
	position: relative;
}

.avaliacao-candidato {
	font-weight: bold;
	font-size: 1em;
}

.atividade-chevron {
	cursor: pointer;
}

.atividade-chevron:hover {
	color: #333;
}

.atividade-image-gallery .image-gallery-slides img {
	max-height: 75vh;
	width: auto !important;
	margin: auto;
	position: relative;
	display: block;
}

.atividade-image-gallery .image-gallery-content.fullscreen .image-gallery-slide img {
	max-height: 100vh;
}

.atividade-image-gallery .image-gallery-thumbnail {
	width: 50px;
	height: 50px;
	overflow: hidden;
}

.info-roteiro-aprovar span {
	padding: 10px;
}

.transicao {
	transition: all ease-in-out 0.3s;
	overflow: hidden;
}

.transicao.ui.segment:hover {
	background: #fafafa;
}

.clickable {
	cursor: pointer;
}

.avaliacao-comentario {
	font-size: 16px;
	color: #999;
	font-style: italic;
}

.info-oportunidade-aprovar {
	left: 1rem;
	position: absolute;
	transform: translateX(-50%);
	background: #4f1e63;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	font-size: 8px;
	text-align: center;
	color: #fff;
}

.info-oportunidade-aprovar i.icon {
	top: 50%;
	transform: translateY(-50%);
	display: block !important;
	position: relative;
	margin: auto;
}

.item-info.auto-height {
	min-height: 35px;
	height: auto;
}

.item-info .bold {
	font-weight: 700;
	line-height: 35px;
	font-size: 1.28571429rem;
}

.item-info.first .info-oportunidade-aprovar {
	width: 50px;
	height: 50px;
	font-size: 12px;
}

.item-info .detalhe-info span {
	display: block;
}

.item-info .detalhe-info span:last-child {
	font-size: 14px;
	font-weight: normal;
}

.item-info .detalhe-info {
	font-size: 18px;
	font-weight: bold;
}

.item-info {
	padding-left: 30px;
	padding-bottom: 15px;
	font-size: 18px;
	border-left: 2px solid #662780;
}

.info-subitem {
	width: 10px;
	height: 10px;
	background: #4f1e63;
	position: absolute;
	border-radius: 50%;
	left: -1px;
	z-index: 1000;
	top: 20px;
	transform: translateX(-50%);
}

.list-item-atividades-aprovar {
	width: 100%;
	min-height: 45px;
	position: relative;
	border-left: 2px solid #662780;
}

.list-item-atividades-aprovar .liaa-icon {
	width: 64px;
	height: 45px;
	padding-left: 30px;
	float: left;
	position: relative;
}

.list-item-atividades-aprovar .liaa-icon .icon {
	top: 50%;
	display: block;
	position: relative;
	margin: auto;
	transform: translateY(-50%);
}

.list-item-atividades-aprovar .liaa-content span {
	display: block;
	line-height: 12px;
}
.list-item-atividades-aprovar .liaa-content {
	width: calc(100% - 65px);
	height: auto;
	display: inline-block;
	padding: 5px;
	padding-bottom: 10px;
	padding-left: 15px;
	line-height: 17px;
	font-size: 12px;
	color: #666;
}

.list-item-atividades-aprovar .liaa-title {
	font-weight: bold;
	font-size: 1.2em;
}

.promotor-aprovado-detalhes span {
	display: block;
}

.promotor-aprovado-detalhes {
	line-height: 25px;
}

.recorrencia {
	display: flex;
	flex-direction: row;
}

.ativo {
	background: #35be35 !important;
}

.recorrencia button {
	padding: 8px 12px 8px 12px;
	font-size: 12px;
	color: #fff;
	border: none;
	background: #666;
	border-radius: 25px;
	outline: none;
	cursor: pointer;
	margin-right: 5px;
}

.recorrencia button:hover {
	background-color: #35be35;
}

.average-rate-usuario {
	display: block;
	font-weight: 300;
	text-align: center;
	color: gray !important;
}

.average-rate-usuario i[class="active icon"] {
	color: gray !important;
}

.promotor-pagamento span:first-child {
	font-size: 16px;
	font-weight: bold;
}

.promotor-pagamento span:last-child {
	font-weight: 300;
}
.valor-total-pagamento-cartao-label {
	display: block;
	color: #999;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}
.valor-total-pagamento-cartao {
	display: block;
	color: #333;
	text-align: center;
	font-size: 3rem;
	line-height: 3rem;
	margin-bottom: 15px;
}

.roteiro-evento {
	padding: 14px 10px !important;
	align-items: center;
}

.roteiro-evento > .content {
	margin-left: 20px;
}

.roteiro-evento > .content p {
	line-height: inherit;
}

.ui.segment.roteiro-avaliacao {
	border: none;
	border-radius: 0 !important;
	box-shadow: none;
	padding: 40px 20px;
}

.ui.feed > .event.roteiro-evento:not(:last-child),
.ui.segment.roteiro-avaliacao:not(:last-child) {
	border-bottom: 1px solid #e0dfdf;
}

@media only screen and (max-width: 767px) {
	.ui.segment.estatistica-usuario {
		display: none !important;
	}

	.ui.stackable.grid > .wide.column .sem-comentario {
		width: 50% !important;
	}
}

.statistic > .estatistica-valor {
	min-width: 35%;
	text-align: right !important;
}

.statistic > .estatistica-label {
	min-width: 35%;
	text-align: left !important;
}

.ui.basic.button.voltar-botao,
a.ui.red.voltar-botao {
	border-radius: 5px !important;
	background-image: none !important;
	background: none !important;
}

.ui.basic.button.voltar-button,
.ui.basic.button.help-button {
	color: #fff !important;
	background-color: rgba(255, 255, 255, 0.1) !important;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 1) inset !important;
}
.ui.basic.button.voltar-button > * {
	color: #fff !important;
	transition: all 500ms ease-in;
}
.ui.basic.button.help-button > * {
	color: #fff !important;
	transition: all 200ms ease-in;
}
.ui.basic.button.help-button:hover,
.ui.basic.button.help-button:hover > * {
	color: #662780 !important;
}

.roteiro-loader {
	line-height: 2.2rem;
}

.ui.modal.clonar-oportunidade {
	padding: 0 20px 0 20px;
	border-radius: 10px;
	overflow: hidden;
	max-width: 600px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 100000;
}

.ui.modal.clonar-oportunidade > .header {
	text-align: center;
	border-bottom: none;
	padding-bottom: 0px !important;
}

.ui.modal.clonar-oportunidade > .content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ui.modal.modal-confirmacao {
	padding: 0 20px;
	border-radius: 10px;
	overflow: hidden;
	width: 600px;
	height: auto;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 100000;
	align-items: center;
}

.ui.modal.modal-confirmacao > .header {
	text-align: center;
	border-bottom: none;
	padding-bottom: 0 !important;
}

.ui.modal.modal-confirmacao > .content {
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 41px);
	text-align: center;
}

.modal-confirmacao-texto {
	flex-grow: 1;
	padding-bottom: 20px;
}

.modal-confirmacao-texto p {
	text-align: center;
	font-size: 20px;
	padding-bottom: 10px;
}
.modal-confirmacao-texto p.info {
	text-align: center;
	font-size: 14px;
	padding-bottom: 10px;
}

.modal-confirmacao-texto span {
	font-weight: bold;
	font-size: 20px;
	color: #662780;
}

.modal-confirmacao-buttons {
	display: flex;
	height: 36px;
}

.modal-confirmacao-buttons > button {
	flex-grow: 1;
}

.verificacao-email-icon {
	font-size: 14rem !important;
	line-height: 15rem !important;
	margin: 83px 0 30px !important;
}
.select-loja.ui.selection.dropdown .menu > .item > .description {
	font-size: 12px;
	color: #999;
	float: none;
	display: block;
	margin: 0;
}
.select-loja.ui.selection.dropdown .menu > .item > .text {
	font-size: 14px;
	color: #333;
	font-weight: bold;
}

.select-loja.ui.selection.dropdown .menu > .item {
	display: flex;
	flex-direction: column-reverse;
}

.novo-roteiro h1,
.novo-roteiro h4,
.novo-roteiro h5 {
	text-align: center;
}

.novo-roteiro h5 {
	margin: 20px 0;
}

.novo-roteiro .fields {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}

input[type="text"].valor-roteiro {
	border: none !important;
}
.valor-roteiro {
	display: flex;
	text-align: center;
	color: #7501c0 !important;
	font-size: 30px;
	font-weight: bold !important;
	line-height: 1rem !important;
	padding: 15px 15px !important;
	outline: none;
	width: 100%;
}

.novo-roteiro .pagarei.placeholder {
	color: #666 !important;
	border-bottom: 1px solid #666 !important;
}

.input-border-color {
	border: 1px solid #7501c0 !important;
}

.dropdown-style {
	border-radius: 5px;
	padding: 8px 14px;
	width: auto !important;
}

.ui.dropdown.dropdown-style .menu {
	width: 100%;
}

.ui.dropdown.dropdown-style .dropdown.icon {
	margin-top: 4px;
}

.nota-fiscal {
	display: flex;
	width: 300px;
	color: #fff;
	flex-grow: 1;
	text-align: center;
	margin: 0 auto;
}

.nota-fiscal-button {
	padding: 12px 10px;
	width: 150px;
	cursor: pointer;
	border: none;
	color: #fff;
	background-color: #666;
}
.nota-fiscal-button.left,
.nota-fiscal-button.right {
	outline: none;
}

.nota-fiscal-button.left {
	padding-left: 20px;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.nota-fiscal-button.left.active,
.nota-fiscal-button.left:hover {
	background-color: #35be35;
}

.nota-fiscal-button.right {
	padding-right: 16px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}

.nota-fiscal-button.right.active,
.nota-fiscal-button.right:hover {
	background-color: #f44336;
}

.ui.segment.atividade-roteiro {
	border: none !important;
	border-radius: 9px !important;
	margin: 10px 0 0 0;
}

.atividade-roteiro {
	color: #fff;
	display: flex;
	cursor: pointer;
}

.atividade-roteiro.purple {
	background-color: #662780 !important;
}

.atividade-roteiro.blue {
	background-color: #1e88e5 !important;
}

.atividade-roteiro.orange {
	background-color: #ff6f00 !important;
}

.atividade-roteiro.green {
	background-color: #35be35 !important;
}

.box-icon {
	margin: 5px 0 0 7px !important;
	color: #333 !important;
}

.checkbox-icon {
	margin: 4px 0 0 5px !important;
	color: #35be35 !important;
}

.delete-icon {
	float: none !important;
	margin: 5px 0 0 1px !important;
	color: #f44336 !important;
}

.lista-de-produtos {
	margin: 30px 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-grow: 1;
}

.produto-selecionado {
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	border-radius: 5px;
	margin-left: 26.5px;
	padding-top: 10px;
}

.produto-selecionado:not(:last-child) {
	margin-bottom: 10px;
}

.produto-selecionado::before {
	content: "\2022";
	width: 22px;
	height: 22px;
	font-size: 50px;
	margin-top: 19px;
	margin-left: -39px;
	position: absolute;
}
.produto-selecionado-container {
	margin-left: 45px !important;
}
.produto-selecionado-container.blue {
	border-left: 5px solid #1e88e5 !important;
}
.produto-selecionado-container.purple {
	border-left: 5px solid #662780 !important;
}
.produto-selecionado-container.green {
	border-left: 5px solid #35be35 !important;
}
.produto-selecionado-container.orange {
	border-left: 5px solid #ff6f00 !important;
}
.produto-selecionado.blue::before {
	color: #1e88e5 !important;
}
.produto-selecionado.purple::before {
	color: #662780 !important;
}
.produto-selecionado.green::before {
	color: #35be35 !important;
}
.produto-selecionado.orange::before {
	color: #ff6f00 !important;
}

.contador-button {
	cursor: pointer;
	font-weight: bold;
	width: 15px;
	text-align: center;
	font-size: 13px;
}

.ui.dropdown.select-loja,
.ui.dropdown.select-tipo {
	padding-top: 9px;
	padding-bottom: 9px;
}

.novo-roteiro .equal.width.fields {
	margin-left: 0;
	margin-right: 0;
}

.react-tagsinput {
	border: 1px solid #e8e8e8 !important;
	border-radius: 5px !important;
}

.react-tagsinput-input {
	width: 60% !important;
	border: none !important;
	border-radius: 5px !important;
	padding-top: 5px !important;
}

.react-tagsinput-tag {
	color: #333 !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	background-color: #e8e8e8 !important;
	border: none !important;
	border-radius: 5px !important;
}

.react-tagsinput-remove {
	color: #f44336;
}

.input-radius {
	border-radius: 5px !important;
}

.checkbox-selecao-automatica {
	background: #eee;
	display: table;
	position: relative;
	margin: auto;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 15px;
	padding-bottom: 10px;
	border-radius: 10px;
	transition: all ease-in-out 0.2s;
}
.checkbox-selecao-automatica.selecionado {
	background: rgba(53, 190, 53, 0.25);
	color: #35be35;
}

.ui.pointing.secondary.menu {
	margin-top: 20px !important;
}

i.icon.arrow.down:before {
	content: "\f063";
	padding-left: 7px;
}

.lista-icones-atividades-secoes {
	display: flex;
	flex-direction: row;
}

.fc-event {
	margin-bottom: 5px;
}
.fc .fc-event {
	background-color: #f4f4f4 !important;
	cursor: pointer;
	color: black !important;
}
/* .fc-button{
	pointer-events: auto!important;
	position: relative!important;

}
.fc-button[title]{
	pointer-events: auto!important;
}
.fc-button[title]:hover::after{
	content: none!important;
} */
@media only screen and (max-width: 795px) {
	.lista-icones-atividades-secoes {
		width: 100px;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
